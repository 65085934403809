import React, {Fragment, Component} from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithPopup, signOut } from 'firebase/auth';
import logo from './iconroundmin.png';
import shareIcon from './share.png';
import './App.css';
import Dashboard from './Dashboard.js';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { get, put, del } from 'aws-amplify/api';

Amplify.configure(amplifyconfig);

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  appId: process.env.REACT_APP_appId,
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

const providerGG = new GoogleAuthProvider();

export default class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      auth: 0,
      loading: true,
      userEmail: undefined,
      showEmailLogin: false,
      emailInput: undefined,
      emailSent: false
    }
    this.logout = this.logout.bind(this)
  }

  loginGG = () => {
    signInWithPopup(auth, providerGG)
    .then(async(result) => {
      this.setState({loading: true})
      const user = result.user;
      this.setState({userEmail: user.email})
      

      try {
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + user.email})
        const {body} = await getOp.response
        const getResponse = await body.json()

        //If the response status is false, meaning the fetched user does not yet exist, create a new user to database
        //If the response status is the user email, then user exists, do nothing, and login as usual
        if (getResponse.status === false) {
          try {
            const timestamp = new Date().getTime()
            const userToCreate = {EmailID: user.email, EventsEmails: [user.email], Photos: [], Cities: ['New York, NY'], LastActiveTimestamp: timestamp, CreatedTimestamp: timestamp, GenderFinalized: false, AccountStatus: true, NotifyInvite: true, NotifyST: true, NotifyLT: true, Credits: 10, Invitations: [], ScheduledDates: [], Availability: [{Monday: undefined},{Tuesday: undefined},{Wednesday: undefined},{Thursday: undefined},{Friday: undefined},{Saturday: undefined},{Sunday: undefined}]}
            const putOp = put({
              apiName: 'usersWebAppAPI',
              path: '/users',
              options: {
                body: userToCreate
              }
            })
            const putResponse = await putOp.response

            fetch('https://us-central1-amiccio-f3188.cloudfunctions.net/emailsFunctionVD', {
              method: 'POST',
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                email: user.email,
                key: 1862384764
              })
             }).catch((err)=>{})

             this.checkReferral()

             // After all user creation steps, navigate to dashboard
             this.setState({auth: 1})
             this.setState({loading: false})


             logEvent(analytics, 'New User Signup')
          } catch (error) {
            this.setState({loading: false})
            alert('Whoops, an error occurred when creating your profile. Please log out and try again later')
          }
        } else {
          // Get user from database returned a result, so passed firebases auth and database retreival
          this.setState({auth: 1})
          this.setState({loading: false})
        }
      } catch (error) {
        this.setState({loading: false})
        console.log(error)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  sendEmailLoginLink = () => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    }
    if (this.state.emailInput) {
      sendSignInLinkToEmail(auth, this.state.emailInput, actionCodeSettings).then(() => {
        window.localStorage.setItem('emailForSignIn', this.state.emailInput)
        this.setState({emailSent: true})
        console.log('email sent')
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  checkIsLoginEmailLink = () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // If current url is a signin link from firebase
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        // Current browser is not the same browser as where email was sent from
        this.setState({loading: false})
      } else {
        // Current browser is the same as original browser -> signin to get auth user
        signInWithEmailLink(auth, email, window.location.href).then(async(result) => {
          this.setState({loading: true})
          const user = result.user;
          this.setState({userEmail: user.email})
      
          try {
            const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + user.email})
            const {body} = await getOp.response
            const getResponse = await body.json()
    
            //If the response status is false, meaning the fetched user does not yet exist, create a new user to database
            //If the response status is the user email, then user exists, do nothing, and login as usual
            if (getResponse.status === false) {
              try {
                const timestamp = new Date().getTime()
                const userToCreate = {EmailID: user.email, EventsEmails: [user.email], Photos: [], Cities: ['New York, NY'], LastActiveTimestamp: timestamp, CreatedTimestamp: timestamp, GenderFinalized: false, AccountStatus: true, NotifyInvite: true, NotifyST: true, NotifyLT: true, Credits: 10, Invitations: [], ScheduledDates: [], Availability: [{Monday: undefined},{Tuesday: undefined},{Wednesday: undefined},{Thursday: undefined},{Friday: undefined},{Saturday: undefined},{Sunday: undefined}]}
                const putOp = put({
                  apiName: 'usersWebAppAPI',
                  path: '/users',
                  options: {
                    body: userToCreate
                  }
                })
                const putResponse = await putOp.response
    
                fetch('https://us-central1-amiccio-f3188.cloudfunctions.net/emailsFunctionVD', {
                  method: 'POST',
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    email: user.email,
                    key: 1862384764
                  })
                 }).catch((err)=>{})
    
                 this.checkReferral()
    
                 // After all user creation steps, navigate to dashboard
                 this.setState({auth: 1})
                 this.setState({loading: false})
    
                 logEvent(analytics, 'New User Signup')
              } catch (error) {
                this.setState({loading: false})
                alert('Whoops, an error occurred when creating your profile. Please log out and try again later')
              }
            } else {
              // Get user from database returned a result, so passed firebases auth and database retreival
              this.setState({auth: 1})
              this.setState({loading: false})
            }
          } catch (error) {
            this.setState({loading: false})
            console.log(error)
          }       
        }).catch((error) => {
          console.log(error)
          this.setState({loading: false})
        })
      }
    } else {
      // Not firebase signin link, do nothing. GetCurrentUser already handles checking current auth
      this.setState({loading: false})
    }
  }


  logout = () => {
    signOut(auth).then(() => {
      this.setState({auth: 0})
      this.setState({userEmail: undefined})
    }).catch((error) => {
      console.log(error)
    })
  }

  getCurrentUser = () => {
    const auth = getAuth();
    const user = auth.currentUser; 
    if (user) {
      this.setState({userEmail: user.email})
      this.setState({auth: 1})
      this.setState({loading: false})
    } else {
      let email = window.localStorage.getItem('emailForSignIn')
      if (email) {
        this.setState({emailInput: email})
        this.checkIsLoginEmailLink()
      } else {
        this.setState({loading: false})
        this.setState({auth: 0})
      }
    }
  }

  checkReferral = async () => {
    const params = new URLSearchParams(window.location.search)
    const referrerParam = params.get('referrer')

    if (referrerParam != null && referrerParam != undefined && referrerParam) {
      try {
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + referrerParam})
        const {body} = await getOp.response
        const getResponse = await body.json()

        if (getResponse.status === false) {
          // Do nothing - referrer info exists but is incorrect or user does not exist
        } else {
          getResponse.Credits+= 20

          const putOp = put({
            apiName: 'usersWebAppAPI',
            path: '/users',
            options: {
              body: getResponse
            }
          })
          const putResponse = await putOp.response

          fetch('https://us-central1-amiccio-f3188.cloudfunctions.net/referralConfirmationFunctionVD', {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: referrerParam,
              key: 1862384764
            }),
          }).catch((err)=>{
            console.log(err)
          })

          logEvent(analytics, 'Referral Completed')
        }
      } catch (error) {
        // Nothing to do - referrer info exists but is incorrect or user does not exist
      }
    } else {
      // Nothing to do - no referrer info
    }
  }

  componentDidMount() {
    setTimeout(()=> {
      this.getCurrentUser()
      logEvent(analytics, 'Login Page View')
    }, 800)
  }

  
  render(){
    const styles = {
      app: {
        textAlign: 'center',
        margin: '0px',
      },
      loginPage: {
        display: this.state.auth === 0 ? true : 'none',
        paddingTop: '100px',
        minHeight: '101vh',
        backgroundColor: 'black',
        overflow: 'auto'
      },
      title: {
        color: '#ffcc00',
        fontSize: '32px',
        marginTop: '30px'
      },
      subTitle: {
        color: 'white',
        fontSize: '18px',
        width: '400px',
        margin: '30px auto',
      },
      loginContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        margin: '40px',
        marginBottom: '200px',
        display: this.state.loading === true ? 'none' : true
      },
      googleLogin: {
        color: 'white',
        backgroundColor: '#be2011',
        textAlign: 'center',
        width: '200px',
        padding: '12px',
        borderRadius: '20px',
        cursor: 'pointer',
        margin: '20px auto',
        fontFamily: 'arial'
      },
      emailLogin: {
        color: 'white',
        backgroundColor: 'purple',
        textAlign: 'center',
        width: '200px',
        padding: '12px',
        borderRadius: '20px',
        cursor: 'pointer',
        margin: '20px auto',
        fontFamily: 'arial',
      },
      logout: {
        color: 'white',
        backgroundColor: '#000000',
        width: '120px',
        padding: '12px',
        borderRadius: '20px',
        margin: '40px auto',
        cursor: 'pointer',
        fontFamily: 'arial',
      },
      logoContainer: {
        display: 'flex',
        justifyContent: 'center',
      },
      innerlogo: {
        borderRadius: '50%',
      },
      eventsPageLink: {
        color: 'white',
        fontSize: '18px',
        width: '180px',
        textAlign: 'center',
        paddingBottom: '60px',
        display: this.state.loading === true ? 'none' : true
      },
      loader: {
        display: this.state.loading === true ? true : 'none'
      },
      shareDownloadApp: {
        backgroundColor: '#ffcc00',
        color: 'white',
        textAlign: 'center',
        width: '200px',
        padding: '12px',
        borderRadius: '20px',
        cursor: 'pointer',
        margin: '20px auto',
        fontFamily: 'arial',
      },
      emailContainer: {
        borderRadius: '10px',
        width: '240px',
        margin: '0px auto',
        display: this.state.showEmailLogin === true ? true : 'none'
      },
      emailInput: {
        padding: '10px',
        border: 'none',
        borderRadius: '4px',
        width: '200px',
        justifySelf: 'left',
      },
      sendLinkButton: {
        margin: '20px auto',
        backgroundColor: 'purple',
        color: 'white',
        padding: '10px',
        borderRadius: '10px',
        width: '100px',
        cursor: 'pointer',
        fontFamily: 'arial',
        fontSize: '14px'
      },
      sentBox: {
        color: 'white',
        width: '240px',
        margin: '30px auto',
        border: 'solid 1px white',
        borderRadius: '10px',
        padding: '16px'
      },
      cancelButton: {
        color: 'white',
        padding: '8px 10px',
        borderRadius: '10px',
        border: 'solid 1px white',
        ursor: 'pointer',
        fontFamily: 'arial',
        fontSize: '14px',
        margin: '20px auto',
        width: '60px',
        cursor: 'pointer'
      }
    }
  
    

    return (
      <div style={styles.app}>

        <div style={styles.loginPage}>
          <div style={styles.logoContainer}><img src={logo} width="100" height="100" style={styles.innerlogo}></img></div>
          <div style={styles.title}>Amiccio Video Dating</div>
          <div style={styles.subTitle} className='subTitleRsp'>Think calendar invites for video dates. Skip swiping, chatting, bots. Sign up to meet real people!</div>
          <div style={styles.loginContainer}>
            <div style={styles.googleLogin} onClick={()=> this.loginGG()}>Continue with Google</div>
            <div style={styles.emailLogin} onClick={()=> this.setState({showEmailLogin: true})}>Login with Email Link</div>
            {this.state.emailSent === false ? <div style={styles.emailContainer}>
              <input style={styles.emailInput} defaultValue={undefined} placeholder={'Enter Email'} value={this.state.emailInput} onChange={(e)=> this.setState({emailInput: e.target.value})}/>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={styles.cancelButton} onClick={()=> {this.setState({showEmailLogin: false}); window.localStorage.removeItem('emailForSignIn')}}>Cancel</div>
                <div style={styles.sendLinkButton} onClick={()=> this.sendEmailLoginLink()}>Send Link</div>
              </div>
              
            </div> : <div style={styles.sentBox}>Login link sent! Check your email. Use the same device and browser.</div>}
            <div style={{color: 'white', width: '240px', margin: '40px auto', marginBottom: '20px'}}>You can also download as an app by "adding to homescreen"</div>
            <img src={shareIcon} width={36} height={36}/>
          </div>
          <div style={styles.loader} id='loader'></div>
          <div ><a style={styles.eventsPageLink} href='https://www.amiccio.com/Events/' target='blank'>Back to Events Schedule</a></div>
        </div>

        {this.state.auth === 1? <Dashboard logout={()=> this.logout()} /> : undefined}

      </div>
    )
  }
  
}
