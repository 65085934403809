import React, {Fragment, Component} from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithPopup, signOut } from 'firebase/auth';
import './Dashboard.css';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';
import { get, put, del } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import cameraIcon from './camera.png';
import calendarIcon from './calendar.png';
import locationIcon from './location.png';
import peopleIcon from './people.png';
import briefcaseIcon from './briefcase.png';

Amplify.configure(amplifyconfig);


const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  appId: process.env.REACT_APP_appId,
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

const providerGG = new GoogleAuthProvider();

export default class Dashboard extends Component {
  constructor(props){
    super(props);
    this.state = {
      nav: 3,
      loading: false,
      userEmail: undefined,
      user: {},

      accountEmail: undefined,
      eventsEmail: undefined,
      accountStatus: true,
      city: 'New York, NY',
      meetingUrl: undefined,
      notifyInvite: undefined,
      notifyST: undefined,
      notifyLT: undefined,
      editingSettings: false,

      firstName: undefined,
      bio: undefined,
      gender: undefined,
      age: undefined,
      profession: undefined,
      photo1: undefined,
      photoFile1: undefined,
      photo2: undefined,
      photoFile2: undefined,
      photo3: undefined,
      photoFile3: undefined,
      photo4: undefined,
      photoFile4: undefined,
      editingProfile: false,

      activeUpcomingStatus: 1,

      loadedUsers: [],

      viewingUser: undefined,
      counterA: 0,
      counterB: 1,
      counterC: 2,
      counterD: 3,

      availMonday: undefined,
      availTuesday: undefined,
      availWednesday: undefined,
      availThursday: undefined,
      availFriday: undefined,
      availSaturday: undefined,
      availSunday: undefined,

      editingAvailability: false,

      invitations: [],
      scheduledDates: [],

      invDateValue: undefined,
      invDateTimestamp: undefined,
      formattedDate: undefined,

      showFAQ: false,
      showGetInvites: false
    }
  }

  setDate = (value) => {
    this.setState({dateValue: value})
  }

  getCurrentUser = async () => {
    const user = auth.currentUser;   
    if (user) {
      this.setState({userEmail: user.email})
      try {
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + user.email})
        const {body} = await getOp.response
        const getResponse = await body.json()
        this.setState({user: getResponse})

        this.setState({
          accountEmail: getResponse.EmailID,
          eventsEmail: getResponse.EventsEmails ? getResponse.EventsEmails[0] : undefined,
          accountStatus: getResponse.AccountStatus,
          city: getResponse.Cities[0],
          notifyInvite: getResponse.NotifyInvite,
          notifyST: getResponse.NotifyST,
          notifyLT: getResponse.NotifyLT,
          firstName: getResponse.FirstName,
          bio: getResponse.Bio,
          meetingUrl: getResponse.MeetingUrl,
          profession: getResponse.Profession,
          gender: getResponse.Gender,
          age: getResponse.Age,
          photo1: getResponse.Photos[0],
          photo2: getResponse.Photos[1],
          photo3: getResponse.Photos[2],
          photo4: getResponse.Photos[3],
          availMonday: getResponse.Availability[0].Monday,
          availTuesday: getResponse.Availability[1].Tuesday,
          availWednesday: getResponse.Availability[2].Wednesday,
          availThursday: getResponse.Availability[3].Thursday,
          availFriday: getResponse.Availability[4].Friday,
          availSaturday: getResponse.Availability[5].Saturday,
          availSunday: getResponse.Availability[6].Sunday,
          invitations: getResponse.Invitations,
          scheduledDates: getResponse.ScheduledDates

        })

        // Update last active timestamp
        const timestamp = new Date().getTime()
        const userToUpdate = getResponse
        userToUpdate.LastActiveTimestamp = timestamp
        const putOp = put({
          apiName: 'usersWebAppAPI',
          path: '/users',
          options: {
            body: userToUpdate
          }
        })
        const putResponse = await putOp.response
      } catch (error) {
        console.log(error)
      }
    } else {
      this.props.logout()
    }
  }

  saveSettings = async () => {
    try {
      this.setState({loading: true})

      // Load most recent database user first
      const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + this.state.userEmail})
      const {body} = await getOp.response
      const getResponse = await body.json()
      this.setState({user: getResponse})

      // Update most recent database user with settings fields that could have changed
      const userToUpdate = this.state.user
      userToUpdate.EventsEmails = [this.state.eventsEmail]
      userToUpdate.AccountStatus = this.state.accountStatus
      userToUpdate.Cities = [this.state.city]
      userToUpdate.MeetingUrl = this.state.meetingUrl
      userToUpdate.NotifyInvite = this.state.notifyInvite
      userToUpdate.NotifyST = this.state.notifyST
      userToUpdate.NotifyLT = this.state.notifyLT

      // Write the updated user to database
      const putOp = put({
        apiName: 'usersWebAppAPI',
        path: '/users',
        options: {
          body: userToUpdate
        }
      })
      const putResponse = await putOp.response

      // Update local state user
      this.setState({user: userToUpdate})
      this.setState({editingSettings: false})
      this.setState({loading: false})
    } catch (error) {
      console.log(error)
      this.setState({loading: false})
    }
  }

  saveProfile = async () => {
    if (this.state.photoFile1 === undefined && this.state.photoFile2 === undefined && this.state.photoFile3 === undefined && this.state.photoFile4 === undefined) {
      try {
        // If there are no new photos uploaded
        this.setState({loading: true})

        // Load most recent database user first
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + this.state.userEmail})
        const {body} = await getOp.response
        const getResponse = await body.json()
        this.setState({user: getResponse})

        // Update most recent database user with profile fields that could have changed
        const userToUpdate = this.state.user
        userToUpdate.FirstName = this.state.firstName
        userToUpdate.Gender = this.state.gender
        if (userToUpdate.Gender !== undefined) {
          userToUpdate.GenderFinalized = true
        }
        userToUpdate.Bio = this.state.bio
        userToUpdate.Profession = this.state.profession
        if (this.state.age < 18) {
          userToUpdate.Age = 18
          this.setState({age: 18})
        } else {
          userToUpdate.Age = this.state.age
        }
        
        // Write the updated user to database
        const putOp = put({
          apiName: 'usersWebAppAPI',
          path: '/users',
          options: {
            body: userToUpdate
          }
        })
        const putResponse = await putOp.response

        // Update local state user
        this.setState({user: userToUpdate})
        this.setState({editingProfile: false})
        this.setState({loading: false})
      } catch (error) {
        console.log(error)
        this.setState({loading: false})
      }
    } else {
      try {
        // If there are any new photos uploaded
        this.setState({loading: true})

        // Load most recent database user first
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + this.state.userEmail})
        const {body} = await getOp.response
        const getResponse = await body.json()
        this.setState({user: getResponse})

        // Update most recent database user with profile fields that could have changed
        const userToUpdate = this.state.user
        userToUpdate.FirstName = this.state.firstName
        userToUpdate.Gender = this.state.gender
        if (userToUpdate.Gender !== undefined) {
          userToUpdate.GenderFinalized = true
        }
        userToUpdate.Bio = this.state.bio
        userToUpdate.Profession = this.state.profession
        if (this.state.age < 18) {
          userToUpdate.Age = 18
          this.setState({age: 18})
        } else {
          userToUpdate.Age = this.state.age
        }

        const promise1 = new Promise(async(resolve)=>{
          if (this.state.photoFile1 !== undefined) {
            console.log('saving photo1')
            const filename1 = new Date().getTime()
            const result1 = await uploadData({
              key: filename1 + '.jpeg',
              data: this.state.photoFile1,
              options: {
                accessLevel: 'guest',
                contentType: 'image/jpeg'
              }
            }).result
  
            this.setState({photo1: 'https://d10sm22jqs00ve.cloudfront.net/public/' + result1.key}, async()=>{
              userToUpdate.Photos.push(this.state.photo1)
              setTimeout(resolve, 10, 'xxx')
            })
          } else {
            setTimeout(resolve, 10, 'xxx')
          }
        })
        const promise2 = new Promise(async(resolve)=>{
          if (this.state.photoFile2 !== undefined) {
            const filename2 = new Date().getTime()
            const result2 = await uploadData({
              key: filename2 + '.jpeg',
              data: this.state.photoFile2,
              options: {
                accessLevel: 'guest',
                contentType: 'image/jpeg'
              }
            }).result
  
            this.setState({photo2: 'https://d10sm22jqs00ve.cloudfront.net/public/' + result2.key}, async()=>{
              userToUpdate.Photos.push(this.state.photo2)
              setTimeout(resolve, 10, 'xxx')
            })
          } else {
            setTimeout(resolve, 10, 'xxx')
          }
        })
        const promise3 = new Promise(async(resolve)=>{
          if (this.state.photoFile3 !== undefined) {
            const filename3 = new Date().getTime()
            const result3 = await uploadData({
              key: filename3 + '.jpeg',
              data: this.state.photoFile3,
              options: {
                accessLevel: 'guest',
                contentType: 'image/jpeg'
              }
            }).result
  
            this.setState({photo3: 'https://d10sm22jqs00ve.cloudfront.net/public/' + result3.key}, async()=>{
              userToUpdate.Photos.push(this.state.photo3)
              setTimeout(resolve, 10, 'xxx')
            })
          } else {
            setTimeout(resolve, 10, 'xxx')
          }
        })
        const promise4 = new Promise(async(resolve)=>{
          if (this.state.photoFile4 !== undefined) {
            const filename4 = new Date().getTime()
            const result4 = await uploadData({
              key: filename4 + '.jpeg',
              data: this.state.photoFile4,
              options: {
                accessLevel: 'guest',
                contentType: 'image/jpeg'
              }
            }).result
  
            this.setState({photo4: 'https://d10sm22jqs00ve.cloudfront.net/public/' + result4.key}, async()=>{
              userToUpdate.Photos.push(this.state.photo4)
              setTimeout(resolve, 10, 'xxx')
            })
          } else {
            setTimeout(resolve, 10, 'xxx')
          }
        })

        Promise.all([promise1, promise2, promise3, promise4]).then(async()=>{
          // Write the updated user to database
          const putOp = put({
            apiName: 'usersWebAppAPI',
            path: '/users',
            options: {
              body: userToUpdate
            }
          })
          const putResponse = await putOp.response

          // Update local state user
          this.setState({user: userToUpdate})
          this.setState({editingProfile: false})
          this.setState({loading: false})
          this.setState({photoFile1: undefined})
          this.setState({photoFile2: undefined})
          this.setState({photoFile3: undefined})
          this.setState({photoFile4: undefined})
        }).catch((error)=>{
          console.log(error)
          this.setState({editingProfile: false})
          this.setState({loading: false})
          this.setState({photoFile1: undefined})
          this.setState({photoFile2: undefined})
          this.setState({photoFile3: undefined})
          this.setState({photoFile4: undefined})
        })
      
      } catch (error) {
        console.log(error)
        this.setState({editingProfile: false})
        this.setState({loading: false})
        this.setState({photoFile1: undefined})
        this.setState({photoFile2: undefined})
        this.setState({photoFile3: undefined})
        this.setState({photoFile4: undefined})
      }
    }
  }

  onImageChange1 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0]
      this.setState({
        photo1: URL.createObjectURL(img),
        photoFile1: img
      })
    }
  }

  onImageChange2 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0]
      this.setState({
        photo2: URL.createObjectURL(img),
        photoFile2: img
      })
    }
  }

  onImageChange3 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0]
      this.setState({
        photo3: URL.createObjectURL(img),
        photoFile3: img
      })
    }
  }

  onImageChange4 = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0]
      this.setState({
        photo4: URL.createObjectURL(img),
        photoFile4: img
      })
    }
  }

  loadUsers = async () => {
    if (this.state.user.FirstName !== undefined && this.state.user.Gender !== undefined && this.state.user.Age !== undefined && this.state.user.Photos.length >= 2 && this.state.user.AccountStatus === true) {
      try {
        const getOp = get({apiName: 'usersWebAppAPI', path: '/users'})
        const {body} = await getOp.response
        const getResponse = await body.json()

        getResponse.sort((a,b) => b.LastActiveTimestamp - a.LastActiveTimestamp)
        const filteredByCompleteProfile = getResponse.filter((user)=> user.FirstName !== undefined && user.Gender !== undefined && user.Age !== undefined && user.Photos.length >= 2 && user.AccountStatus === true)
        const filteredByCurrentGender = this.state.user.Gender === 'Man' ? filteredByCompleteProfile.filter((user)=> user.Gender === 'Woman') : filteredByCompleteProfile.filter((user)=> user.Gender === 'Man')
        this.setState({loadedUsers: filteredByCurrentGender})

        this.setState({loading: false})
      } catch (error) {
        this.setState({loading: false})
        console.log(error)
      }
    } else {
      this.setState({loading: false})
    }
  }

  removePhoto = (photoId) => {
    if (photoId === 1) {
      this.setState({photo1: undefined})
      this.setState({photoFile1: undefined})
      const userObject = this.state.user
      userObject.Photos.splice(0, 1)
      this.setState({user: userObject})
    } else if (photoId === 2) {
      this.setState({photo2: undefined})
      this.setState({photoFile2: undefined})
      const userObject = this.state.user
      userObject.Photos.splice(1, 1)
      this.setState({user: userObject})
    } else if (photoId === 3) {
      this.setState({photo3: undefined})
      this.setState({photoFile3: undefined})
      const userObject = this.state.user
      userObject.Photos.splice(2, 1)
      this.setState({user: userObject})
    }  else if (photoId === 4) {
      this.setState({photo4: undefined})
      this.setState({photoFile4: undefined})
      const userObject = this.state.user
      userObject.Photos.splice(3, 1)
      this.setState({user: userObject})
    }
  }

  openProfile = async (userEmail) => {
    try {
      const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + userEmail})
      const {body} = await getOp.response
      const getResponse = await body.json()
      this.setState({viewingUser: getResponse})

      this.setState({nav: 4})
    } catch (error) {
      console.log('error')
    }
  }


  rotatePhotos = () => {
    let currentPositionA = this.state.counterA
    const memberPhotosLength = this.state.viewingUser.Photos.length

    if (memberPhotosLength === 2) {
      if (currentPositionA === 0) {
        this.setState({counterA: 1})
        this.setState({counterB: 0})
      } else {
        this.setState({counterA: 0})
        this.setState({counterB: 1})
      }
    } else if (memberPhotosLength  === 3) {
      if (currentPositionA === 0) {
        this.setState({counterA: 1})
        this.setState({counterB: 2})
        this.setState({counterC: 0})
      } else if (currentPositionA === 1) {
        this.setState({counterA: 2})
        this.setState({counterB: 0})
        this.setState({counterC: 1})
      } else if (currentPositionA === 2) {
        this.setState({counterA: 0})
        this.setState({counterB: 1})
        this.setState({counterC: 2})
      }
    } else if (memberPhotosLength === 4) {
      if (currentPositionA === 0) {
        this.setState({counterA: 1})
        this.setState({counterB: 2})
        this.setState({counterC: 3})
        this.setState({counterD: 0})
      } else if (currentPositionA === 1) {
        this.setState({counterA: 2})
        this.setState({counterB: 3})
        this.setState({counterC: 0})
        this.setState({counterD: 1})
      } else if (currentPositionA === 2) {
        this.setState({counterA: 3})
        this.setState({counterB: 0})
        this.setState({counterC: 1})
        this.setState({counterD: 2})
      } else if (currentPositionA === 3) {
        this.setState({counterA: 0})
        this.setState({counterB: 1})
        this.setState({counterC: 2})
        this.setState({counterD: 3})
      }
    }
  }


  saveAvailability = async () => {
    try {
      this.setState({loading: true})
      const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + this.state.userEmail})
      const {body} = await getOp.response
      const getResponse = await body.json()
      this.setState({user: getResponse})

      const userToUpdate = this.state.user
      userToUpdate.Availability = [{Monday: this.state.availMonday},{Tuesday: this.state.availTuesday},{Wednesday: this.state.availWednesday},{Thursday: this.state.availThursday},{Friday: this.state.availFriday},{Saturday: this.state.availSaturday},{Sunday: this.state.availSunday}]

      const putOp = put({
        apiName: 'usersWebAppAPI',
        path: '/users',
        options: {
          body: userToUpdate
        }
      })
      const putResponse = await putOp.response

      this.setState({editingAvailability: false})
      this.setState({loading: false})
    } catch (error) {
      this.setState({loading: false})
      console.log(error)
    }
  }


  setInvDate = (value) => {
    this.setState({invDateValue: value})
    setTimeout(()=>{
      const dateObject = new Date(this.state.invDateValue)
      const timestamp1 = dateObject.getTime()
      const convertedDate = new Date(timestamp1)
      const formattedDate = convertedDate.toDateString() + ' ' + convertedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'America/New_York' }) + ' ET'

      this.setState({invDateTimestamp: timestamp1})
      this.setState({formattedDate: formattedDate})
    },100)
  }

  sendInvitation  = async () => {
    const currentTime = Date.now()
    if (this.state.invDateTimestamp !== undefined) {
      if (currentTime < this.state.invDateTimestamp) {
        let credits = this.state.user.Credits

        if (credits > 0) {
          try {
            this.setState({loading: true})

            // Add invitation object to invitee
            const invitationObject = {
              requesterEmail: this.state.user.EmailID,
              requesterFirstName: this.state.user.FirstName,
              requesterAge: this.state.user.Age,
              requesterPhotos: this.state.user.Photos,
              inviteeEmail: this.state.viewingUser.EmailID,
              inviteeFirstName: this.state.viewingUser.FirstName,
              inviteeAge: this.state.viewingUser.Age,
              inviteePhotos: this.state.viewingUser.Photos,
              dateTimestamp: this.state.invDateTimestamp,
              dateFormatted: this.state.formattedDate,
              meetingUrl: this.state.user.MeetingUrl,
              sentTimestamp: Date.now(),
              passed: false
            }

            const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + this.state.viewingUser.EmailID})
            const {body} = await getOp.response
            const invitee = await body.json()
            invitee.Invitations.push(invitationObject)

            const putOp1 = put({
              apiName: 'usersWebAppAPI',
              path: '/users',
              options: {
                body: invitee
              }
            })
            const putResponse1 = await putOp1.response
            this.setState({viewingUser: invitee})

            // Decrement credits of video date requeser
            let requester = this.state.user
            credits --
            requester.Credits = credits

            const putOp2 = put({
              apiName: 'usersWebAppAPI',
              path: '/users',
              options: {
                body: requester
              }
            })
            const putResponse2 = await putOp2.response
            this.setState({user: requester})
            this.setState({loading: false})

            logEvent(analytics, 'Sent Date Invitation')

            // Send email notification to invitee
            if (this.state.viewingUser.NotifyInvite === true) {
              fetch('https://us-central1-amiccio-f3188.cloudfunctions.net/inviteNotifyFunctionVD', {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  email: this.state.viewingUser.EventsEmails[0],
                  firstname: this.state.viewingUser.FirstName,
                  datename: this.state.user.FirstName,
                  key: 1862384764
                }),
              }).catch((err)=>{
                console.log(err)
              })  
            }        
          } catch (error) {
            console.log(error)
            this.setState({loading: false})
          }
        } else {
          alert('You already spent all your credits!')
        }
        
      } else {
        alert('Your time is in the past. Set a time in the future!')
      }
    } else {
      alert('Set a time for a video date before sending an invite!')
    }
  }

  acceptInvite = async (invitation) => {
    try {
      // Add accepted invite to scheduled dates of invitee and remove accepted invite from invitations array of invitee
      const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + this.state.userEmail})
      const {body} = await getOp.response
      const getResponse = await body.json()

      const objIndex = getResponse.Invitations.findIndex(obj => obj.dateTimestamp === invitation.dateTimestamp)
      getResponse.ScheduledDates.push(invitation)
      getResponse.Invitations.splice(objIndex, 1)

      const putOp = put({
        apiName: 'usersWebAppAPI',
        path: '/users',
        options: {
          body: getResponse
        }
      })
      const putResponse = await putOp.response
      this.setState({user: getResponse})
      this.setState({invitations: getResponse.Invitations})
      this.setState({scheduledDates: getResponse.ScheduledDates})

      // Add accepted invite to scheduled dates of requester
      const getOp2 = get({apiName: 'usersWebAppAPI', path: '/users/object/' + invitation.requesterEmail})
      const body2 = await getOp2.response
      const getResponse2 = await body2.body.json()
      getResponse2.ScheduledDates.push(invitation)

      const putOp2 = put({
        apiName: 'usersWebAppAPI',
        path: '/users',
        options: {
          body: getResponse2
        }
      })
      const putResponse2 = await putOp2.response

      // Send email notification to both parties
      fetch('https://us-central1-amiccio-f3188.cloudfunctions.net/dateNotifyFunctionVD', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: getResponse.EventsEmails[0],
          firstname: getResponse.FirstName,
          datename: getResponse2.FirstName,
          datetimestamp: invitation.dateTimestamp,
          datetime: invitation.dateFormatted,
          meetingurl: invitation.meetingUrl,
          key: 1862384764
        }),
      }).catch((err)=>{
        console.log(err)
      })
      fetch('https://us-central1-amiccio-f3188.cloudfunctions.net/dateNotifyFunctionVD', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: getResponse2.EventsEmails[0],
          firstname: getResponse2.FirstName,
          datename: getResponse.FirstName,
          datetimestamp: invitation.dateTimestamp,
          datetime: invitation.dateFormatted,
          meetingurl: invitation.meetingUrl,
          key: 1862384764
        }),
      }).catch((err)=>{
        console.log(err)
      })

      logEvent(analytics, 'Accepted Date Invitation')
    } catch (error) {
      console.log(error)
    }
  }

  passInvite = async (invitation) => {
    try {
      const getOp = get({apiName: 'usersWebAppAPI', path: '/users/object/' + this.state.userEmail})
      const {body} = await getOp.response
      const getResponse = await body.json()
      
      const objIndex = getResponse.Invitations.findIndex(obj => obj.dateTimestamp === invitation.dateTimestamp)
      getResponse.Invitations[objIndex] = invitation
      getResponse.Invitations[objIndex].passed = true

      const putOp = put({
        apiName: 'usersWebAppAPI',
        path: '/users',
        options: {
          body: getResponse
        }
      })
      const putResponse = await putOp.response
      this.setState({user: getResponse})
      this.setState({invitations: getResponse.Invitations})
    } catch (error) {
      console.log(error)
    }
  }

  shareReferralLink = async () => {
    try {
      const result = await navigator.share({url: 'https://app.amiccio.com/?referrer=' + this.state.user.EmailID})
      return result
    } catch (error) {
      console.log(error)
    }
  }


  componentDidMount() {
    this.setState({loading: true})
    setTimeout(()=> {
      this.getCurrentUser().then(()=>{
        this.loadUsers()
      })
    }, 800)
  }
  
  render(){
    const styles = {
      logout: {
        color: 'white',
        backgroundColor: '#000000',
        width: '120px',
        padding: '12px',
        borderRadius: '20px',
        margin: '120px 0px',
        cursor: 'pointer',
        justifySelf: 'left',
        marginBottom: '40px',
        fontFamily: 'arial'
      },
      navContainer: {
        width: '100%',
        margin: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'purple',
        color: 'white'
      },
      navButton1: {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      navButton2: {
        color: 'purple',
        backgroundColor: '#ffcc00',
        borderRadius: '30px',
        cursor: 'pointer',
      },

      // Settings -----------------------------------------------------------------------------

      settingsContainer: {
        display: this.state.nav === 0 ? 'grid' : 'none',
      },
      title: {
        color: '#ffcc00',
        fontSize: '40px',
        marginTop: '20px',
        justifySelf: 'left',
      },
      settingsTitleDiv: {
        textAlign: 'left',
        fontWeight: 'bold',
        marginBottom: '10px',
        marginTop: '30px'
      },
      settingsDiv: {
        textAlign: 'left',
        marginBottom: '10px',
        display: this.state.editingSettings === false ? true : 'none'
      },
      settingsInput: {
        marginBottom: '10px',
        padding: '10px',
        paddingLeft: '0px',
        border: 'none',
        borderBottom: 'solid 2px purple',
        minWidth: '240px',
        justifySelf: 'left',
        display: this.state.editingSettings === false ? 'none' : true
      },
      settingsDivA: {
        textAlign: 'left',
        margin: '10px 10px 10px 0px',
      },
      settingsDivB: {
        textAlign: 'left',
        margin: '10px 0px',
        fontWeight: 'bold',
        display: this.state.editingSettings === false ? true : 'none'
      },
      settingsDivC: {
        textAlign: 'left',
        marginBottom: '10px',
      },
      currentStatus: {
        color: 'white',
        backgroundColor: 'purple',
        margin: '6px',
        marginLeft: '0px',
        padding: '8px 20px',
        borderRadius: '10px',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingSettings === false ? 'none' : true
      },
      otherStatus: {
        margin: '6px',
        marginLeft: '0px',
        padding: '6px 16px',
        border: 'solid 2px black',
        borderRadius: '10px',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingSettings === false ? 'none' : true
      },
      editSettingsButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        marginTop: '80px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingSettings === false ? true : 'none'
      },
      saveSettingsButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        marginTop: '80px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingSettings === false ? 'none' : true
      },
      meetingUrlExplanation: {
        backgroundColor: '#ffcc00',
        fontSize: '12px',
        width: '300px',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'left'
      },
      creditsNumber: {
        backgroundColor: '#ffcc00',
        padding: '8px 16px',
        borderRadius: '8px',
        fontWeight: 'bold'
      },
      getCreditsButton: {
        backgroundColor: 'purple',
        color: 'white',
        padding: '8px 16px',
        borderRadius: '8px',
        margin: '0px 12px',
        cursor: 'pointer',
        fontWeight: 'bold',
      },

      // Profile -----------------------------------------------------------------------------

      profileContainer: {
        display: this.state.nav === 1 ? 'grid' : 'none'
      },
      profileDiv: {
        textAlign: 'left',
        marginBottom: '10px',
        marginRight: '40px',
        display: this.state.editingProfile === false ? true : 'none'
      },
      profileInput: {
        marginBottom: '10px',
        padding: '10px',
        paddingLeft: '0px',
        border: 'none',
        borderBottom: 'solid 2px purple',
        minWidth: '240px',
        justifySelf: 'left',
        display: this.state.editingProfile === false ? 'none' : true
      },
      editProfileButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        marginBottom: '100px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === false ? true : 'none'
      },
      saveProfileButton: {
        color: 'white',
        width: '80px',
        margin: '40px 0px',
        marginBottom: '100px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === false ? 'none' : true
      },
      loaderImg: {
        display: this.state.loading === true ? true : 'none',
        justifySelf: 'left',
      },
      loader2Img: {
        display: this.state.loading === true ? true : 'none',
        justifySelf: 'center',
      },
      activeGender: {
        color: 'white',
        backgroundColor: 'purple',
        margin: '5px',
        marginLeft: '0px',
        padding: '6px 16px',
        borderRadius: '10px',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === true ? true : 'none'
      },
      inactiveGender: {
        margin: '5px',
        marginLeft: '0px',
        padding: '4px 14px',
        border: 'solid 2px black',
        borderRadius: '10px',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingProfile === true ? true : 'none'
      },
      genderWarning: {
        marginBottom: '40px',
        marginTop: '20px',
        marginRight: '40px',
        textAlign: 'left'
      },
      photoContainer: {
        margin: '20px',
        marginLeft: '0px',
        width: '120px',
        marginBottom: '30px'
      },
      photoBox: {
        marginBottom: '10px',
        backgroundColor: '#e8daee',
        borderRadius: '10px',
        objectFit: 'cover',
        width: '120px',
        height: '150px'
      },
      photoInputButton: {
        display: this.state.editingProfile === true ? 'block' : 'none',
        fontFamily: 'arial',
        fontSize: '12px',
        backgroundColor: 'purple',
        color: 'white',
        padding: '5.1px 10px',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      photoRemoveButton: {
        display: this.state.editingProfile === true ? true : 'none',
        fontFamily: 'arial',
        fontSize: '12px',
        backgroundColor: 'black',
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginTop: '-4px'
      },

      // Dates -----------------------------------------------------------------------------

      datesContainer: {
        display: this.state.nav === 2 ? 'grid' : 'none',
        margin: '40px 20px'
      },
      datesPagePartition: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      },
      datesLeftPartition: {
        margin: '20px 40px'
      },
      datesRightPartition: {
        margin: '20px 40px'
      },

      setAvailabilityTitle: {
        fontSize: '28px',
        fontWeight: 'bold',
        color: 'purple',
        marginBottom: '40px',
        marginTop: '8px'
      },
      setAvailabilityBox: {
        margin: '20px'
      },
      dayContainer: {
        margin:  '20px 0px',
        borderRadius: '20px',
        border: 'solid 2px #ffcc00',
        display: 'flex',
        overflow: 'hidden'
      },
      dayLabel: {
        color: 'black',
        backgroundColor: '#ffcc00',
        width: '100px',
        padding: '12px'
      },
      hoursInput: {
        padding: '12px',
        paddingLeft: '40px',
        display: this.state.editingAvailability === false ? 'block' : 'none'
      },
      availInput: {
        border: 'none',
        padding: '12px',
        fontSize: '14px',
        paddingLeft: '40px',
        minWidth: '200px',
        display: this.state.editingAvailability === true ? 'block' : 'none'
      },

      editHoursButton: {
        color: 'white',
        width: '80px',
        margin: '60px 0px',
        marginBottom: '100px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingAvailability === false ? true : 'none'
      },
      saveHoursButton: {
        color: 'white',
        width: '80px',
        margin: '60px 0px',
        marginBottom: '100px',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        justifySelf: 'left',
        cursor: 'pointer',
        display: this.state.editingAvailability === false ? 'none' : true
      },

      activeBar: {
        color: 'white',
        backgroundColor: 'purple',
        border: 'solid 2px purple',
        padding: '8px',
        width: '140px',
        cursor: 'pointer'
      },
      inactiveBar: {
        color: 'purple',
        border: 'solid 2px purple',
        padding: '8px',
        width: '140px',
        cursor: 'pointer'
      },
      datesArrayBox: {

      },

      invitationsTitle: {
        color: 'white',
        backgroundColor: 'purple',
        border: 'solid 2px purple',
        padding: '8px',
        width: '200px',
        margin: '40px auto'
      },
      invitationsArrayBox: {

      },
      invitationBox: {
        backgroundColor: '#ffcc00',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'left',
        width: '300px',
        margin: '20px auto',
        position: 'relative'
      },
      acceptButton:  {
        backgroundColor: 'purple',
        padding: '5px 20px',
        borderRadius: '20px',
        border: 'solid 2px purple',
        color: 'white',
        position: 'absolute',
        right: '24px',
        bottom: '70px',
        width: '60px',
        textAlign: 'center',
        cursor: 'pointer'
      },
      passButton: {
        padding: '5px 20px',
        borderRadius: '20px',
        color: 'purple',
        border: 'solid 2px purple',
        position: 'absolute',
        right: '24px',
        bottom: '24px',
        width: '60px',
        textAlign: 'center',
        cursor: 'pointer'
      },
      exploreCTA: {
        backgroundColor: 'purple',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '20px',
        width: '120px',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '100px',
        cursor: 'pointer'
      },
      videoLinkButton: {
        backgroundColor: 'black',
        color: 'white',
        padding: '8px 12px',
        borderRadius: '8px',
        position: 'absolute',
        bottom: '50px',
        right: '40px',
        fontSize: '14px'
      },

      // Explore ------------------------------------------------------------------------------

      exploreContainer: {
        display: this.state.nav === 3 ? 'grid' : 'none',
        marginTop: '40px',
        marginBottom:  '80px'
      },
      exploreTitle: {
        color: '#ffcc00',
        fontSize: '40px',
        marginTop: '20px',
        marginBottom: '20px'
      },

      completeContainer: {
        margin: '40px',
        marginTop: '20px'
      },
      completeButton: {
        margin: '40px auto',
        padding: '12px',
        borderRadius: '20px',
        backgroundColor: 'purple',
        color: 'white',
        fontWeight: 'bold',
        width: '180px',
        cursor: 'pointer'
      },
      completeProfileBox: {
        backgroundColor: '#ffcc00',
        padding: '20px',
        borderRadius: '20px',
        width: '240px',
        margin: '0px auto'
      },

      usersContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: '800px',
        margin: 'auto'
      },
      userContainer: {
        margin: '15px 10px'
      },
      userName: {
        color: 'white',
        fontFamily: 'arial',
        fontWeight: 'bold',
        textAlign: 'left',
        position: 'absolute',
        left: '15px',
        bottom: '15px'
      },
      cameraIcon: {
        position: 'absolute',
        left: '12px',
        top: '15px'
      },
      photoLength: {
        position: 'absolute',
        left: '30px',
        top: '18px',
        fontFamily: 'arial',
        fontSize: '10px',
        color: 'white'
      },
      calendarBox: {
        position: 'absolute',
        right: '15px',
        bottom: '15px',
        backgroundColor: 'purple',
        padding: '8px',
        borderRadius: '8px'
      },


      // View Member ------------------------------------------------------------------------------

      viewMemberContainer: {
        display: this.state.nav === 4 ? 'grid' : 'none',
        margin: '30px',
        marginBottom: '100px'
      },
      pagePartition: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
      },
      leftPartition: {
        minWidth: '200px'
      },
      rightPartition: {
        padding: '20px 40px',
      },
      memberPhotoA: {
        width: '100%',
        aspectRatio: '4/5',
        borderRadius: '10px',
        objectFit: 'cover',
        cursor: 'pointer'
      },
      memberPhotoB: {
        width: '120px',
        aspectRatio: '4/5',
        borderRadius: '10px',
        objectFit: 'cover',
        cursor: 'pointer',
        marginRight: '10px'
      },
      secondaryPhotosContainer: {
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'flex-start',
      },
      memberFirstName: {
        textAlign: 'left',
        fontSize: '30px',
        color: 'black',
        marginBottom: '15px'
      },
      memberAttributesBox: {
        display: 'flex',
        marginBottom: '20px',
        flexWrap: 'wrap'
      },
      memberLocation: {
        textAlign: 'center',
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: '#ffcc00',
        margin: '0px 20px 15px 0px',
        display: 'inline-block',
        whiteSpace: 'nowrap'
      },
      memberCreatedDate: {
        textAlign: 'center',
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: '#ffcc00',
        margin: '0px 20px 15px 0px',
        display: 'inline-block',
        whiteSpace: 'nowrap'
      },
      memberProfession: {
        textAlign: 'center',
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: '#ffcc00',
        margin: '0px 20px 15px 0px',
        display: 'inline-block',
        whiteSpace: 'nowrap'
      },
      memberBio: {
        textAlign: 'left',
        marginBottom: '40px'
      },
      availabilityBox: {
        marginTop: '40px',
        textAlign: 'left',
      },
      dayBox: {
        marginTop: '10px'
      },
      inviteBox: {
        marginTop: '40px',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#ffcc00'
      },
      sendInvButton: {
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: 'purple',
        padding: '12px',
        borderRadius:'24px',
        width: '200px',
        textAlign: 'center',
        marginTop: '30px',
        cursor: 'pointer',
      },
      alreadyInvButton: {
        color: 'white',
        backgroundColor: 'black',
        padding: '8px',
        borderRadius: '8px',
        width: '320px',
        textAlign: 'center'
      },
      invPreviewPhoto: {
        objectFit: 'cover',
        borderRadius: '40px',
        margin: '10px',
        cursor: 'pointer'
      },

      // FAQ ------------------------------------------------------------------------------

      faqButton: {
        position: 'fixed',
        bottom: '40px',
        right: '40px',
        color: 'white',
        backgroundColor: 'purple',
        padding: '10px 12px',
        borderRadius: '12px',
        width: '100px',
        fontWeight: 'bold',
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
      },
      faqBox: {
        display: this.state.showFAQ === true ? true : 'none',
        position: 'fixed',
        bottom: '100px',
        right: '40px',
        backgroundColor: '#ffcc00',
        padding: '20px',
        borderRadius: '10px',
        width: '320px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
      },
      closeFAQButton: {
        color: 'black',
        padding: '6px 10px',
        borderRadius: '6px',
        border: 'solid 1px black',
        marginTop: '20px',
        width: '80px',
        margin: '20px auto',
        marginBottom: '0px',
        cursor: 'pointer',
        fontSize: '14px'
      },

      // FAQ ------------------------------------------------------------------------------

      getInvitesModal: {
        display: this.state.showGetInvites === true ? true : 'none',
        position: 'fixed',
        top: '200px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '320px',
        backgroundColor: '#ffcc00',
        padding: '20px',
        borderRadius: '20px',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
      },
      modalButton: {
        margin:  '30px',
        backgroundColor: 'purple',
        color: 'white',
        padding: '12px 20px',
        borderRadius: '12px',
        cursor: 'pointer',
        fontWeight: 'bold',
      },
      modalButtonA: {
        margin:  '30px',
        marginBottom: '40px',
        backgroundColor: 'black',
        color: 'white',
        padding: '12px 20px',
        borderRadius: '12px',
        fontWeight: 'bold',
        cursor: 'pointer'
      },
      closeModalButton: {
        color: 'black',
        padding: '6px 10px',
        borderRadius: '6px',
        border: 'solid 2px black',
        width: '100px',
        margin: '20px auto',
        marginTop: '40px',
        cursor: 'pointer',
        fontSize: '16px'
      },
      overlay: {
        display: this.state.showGetInvites === true ? true : 'none',
        zIndex: '99',
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
      }
    }

    const loadedUsers = this.state.loadedUsers.map((user, i)=>{
      if (user) {
        return (
          <div key={i} style={styles.userContainer} className='userContainer'>
            <div style={{backgroundImage: `url(${user.Photos[0]})`, borderRadius: '10px', width: '240px', height: '300px', backgroundSize: 'cover', position: 'relative', cursor: 'pointer'}} onClick={()=> {this.openProfile(user.EmailID); logEvent(analytics, 'View User Profile')}} className='userBox'>
              <img src={cameraIcon} width={16}  height={16} style={styles.cameraIcon}/>
              <div style={styles.photoLength}>{user.Photos.length}</div>
              <div style={styles.userName} className='userName'>{user.FirstName}, {user.Age}</div>
              <img src={calendarIcon} width={24}  height={24} style={styles.calendarBox} className='calendarBox'/>
            </div>
          </div>
        )
      }
    })

    const loadedUsersContainer = (
      <div>
        <div style={{marginBottom: '20px', display: 'flex', justifyContent: 'center'}}>
          <div style={styles.creditsNumber}>Video Date Invites: {this.state.user.Credits}</div>
          <div style={styles.getCreditsButton} onClick={()=> {this.setState({showGetInvites: true}); logEvent(analytics, 'Get Invites Button Click')}}>Get More Invites</div>
        </div>
        <div style={styles.usersContainer} className='usersContainer'>
          {loadedUsers}
        </div>
      </div>
      
    )


    const completeProfile = (
      <div style={styles.completeContainer}>
        <div style={styles.completeProfileBox}>
          <div style={{marginBottom: '30px'}}>Your profile is incomplete! Fill out the following to view members you would like to date:</div>
          <ul style={{textAlign: 'left'}}>
            <li>First Name</li>
            <li>Age</li>
            <li>Gender</li>
            <li>At least 2 Photos</li>
          </ul>
        </div>
        <div style={styles.completeButton} onClick={()=> this.setState({nav: 1})}>Complete Profile</div>
      </div>
    )

    const activateAccount = (
      <div style={styles.completeContainer}>
        <div style={styles.completeProfileBox}>Your account is currently paused. Activate to view and date members!</div>
        <div style={styles.completeButton} onClick={()=> this.setState({nav: 0})}>Activate Account</div>
      </div>
    )

    const exploreContent = this.state.user.FirstName && this.state.user.Gender !== undefined && this.state.user.Age !== undefined && this.state.user.Photos.length >= 2 ? (this.state.user.AccountStatus === true ? loadedUsersContainer : activateAccount) : completeProfile

    const memberCreatedDate = this.state.viewingUser ? new Date(this.state.viewingUser.CreatedTimestamp) : undefined
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

    const professionBox = this.state.viewingUser && this.state.viewingUser.Profession ? <div style={styles.memberProfession}><img src={briefcaseIcon} width={12} height={12}/> {this.state.viewingUser.Profession}</div> : undefined


    const upcomingDatesArray = this.state.activeUpcomingStatus === 1 && this.state.scheduledDates.some((date)=> date.dateTimestamp > Date.now()) ? this.state.scheduledDates.map((date, i)=>{
      if (date.dateTimestamp > Date.now()) {
        const shownUserEmail = this.state.userEmail === date.requesterEmail ? date.inviteeEmail : date.requesterEmail
        const shownFirstName = this.state.userEmail === date.requesterEmail ? date.inviteeFirstName : date.requesterFirstName
        const shownAge = this.state.userEmail === date.requesterEmail ? date.inviteeAge : date.requesterAge
        const shownPhotos = this.state.userEmail === date.requesterEmail ? date.inviteePhotos : date.requesterPhotos
        return (
          <div style={styles.invitationBox} key={i}>
            <div style={{margin: '10px'}}><span style={{fontWeight: 'bold'}}>When:</span> {date.dateFormatted}</div>
            <div style={{margin: '10px'}}><span style={{fontWeight: 'bold'}}>Who:</span> {shownFirstName}, {shownAge}</div>
            <img width={80} height={80} src={shownPhotos[0]} style={styles.invPreviewPhoto} onClick={()=> this.openProfile(shownUserEmail)}/>
            <a href={date.meetingUrl} target={'blank'}><div style={styles.videoLinkButton}>Go To Video Meeting</div></a>
          </div>
        )
      }
    }) : ( 
      <div>
        <div>No scheduled dates yet!</div>
        <div style={styles.exploreCTA} onClick={()=> this.setState({nav: 3})}>Explore Members</div>
      </div>
    )

    const pastDatesArray = this.state.activeUpcomingStatus === 0 && this.state.scheduledDates.some((date)=> date.dateTimestamp < Date.now()) ? this.state.scheduledDates.map((date, i)=>{
      if (date.dateTimestamp < Date.now()) {
        const shownUserEmail = this.state.userEmail === date.requesterEmail ? date.inviteeEmail : date.requesterEmail
        const shownFirstName = this.state.userEmail === date.requesterEmail ? date.inviteeFirstName : date.requesterFirstName
        const shownAge = this.state.userEmail === date.requesterEmail ? date.inviteeAge : date.requesterAge
        const shownPhotos = this.state.userEmail === date.requesterEmail ? date.inviteePhotos : date.requesterPhotos
        return (
          <div style={styles.invitationBox} key={i}>
            <div style={{margin: '10px'}}><span style={{fontWeight: 'bold'}}>When:</span> {date.dateFormatted}</div>
            <div style={{margin: '10px'}}><span style={{fontWeight: 'bold'}}>Who:</span> {shownFirstName}, {shownAge}</div>
            <img width={80} height={80} src={shownPhotos[0]} style={styles.invPreviewPhoto} onClick={()=> this.openProfile(shownUserEmail)}/>
          </div>
        )
      }
    }) : ( 
      <div>
        <div>No past dates yet!</div>
        <div style={styles.exploreCTA} onClick={()=> this.setState({nav: 3})}>Explore Members</div>
      </div>
    )

    const currentActiveInvitationsArray = this.state.invitations.filter((inv)=> inv.passed === false && inv.dateTimestamp > Date.now())
    const invitationsArray = currentActiveInvitationsArray.length >= 1 ? this.state.invitations.map((invitation, i)=>{
      return (
        <div style={styles.invitationBox} key={i}>
          <div style={{margin: '10px'}}><span style={{fontWeight: 'bold'}}>When:</span> {invitation.dateFormatted}</div>
          <div style={{margin: '10px'}}><span style={{fontWeight: 'bold'}}>Who:</span> {invitation.requesterFirstName}, {invitation.requesterAge}</div>
          <img width={80} height={80} src={invitation.requesterPhotos[0]} style={styles.invPreviewPhoto} onClick={()=> this.openProfile(invitation.requesterEmail)}/>
          <div style={styles.acceptButton} onClick={()=> this.acceptInvite(invitation)}>Accept</div>
          <div style={styles.passButton} onClick={()=> this.passInvite(invitation)}>Pass</div>
        </div>
      )
    }) : ( 
      <div>
        <div>No invitations yet!</div>
        <div style={styles.exploreCTA} onClick={()=> this.setState({nav: 3})}>Explore Members</div>
      </div>
    )

    const setUrlWarningBox = (
      <div style={{margin: '40px 0px', width: '240px', backgroundColor: '#ffcc00', borderRadius: '10px', padding: '10px'}}>
        <div style={{margin: '20px'}}>You must set your video meeting url to invite members to video dates!</div>
        <div style={{margin: '20px', backgroundColor: 'black', color: 'white', padding: '10px', borderRadius: '10px', cursor: 'pointer'}} onClick={()=> this.setState({nav: 0})}>Go To Settings</div>
      </div>
    )

    const pastInvitations = this.state.viewingUser ? this.state.viewingUser.Invitations.map((invObj, i)=>{
      if (invObj.requesterEmail === this.state.user.EmailID) {
        let timestamp1 = invObj.sentTimestamp
        const convertedDate = new Date(timestamp1)
        const formattedDate = convertedDate.toDateString() + ' ' + convertedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return (
          <div style={{margin: '20px px'}} key={i}>An invite was sent on: {formattedDate}</div>
        )
      }
    }) : undefined

    const requestDateBox = (
      <div style={styles.availabilityBox}>
        <div style={{fontWeight: 'bold', marginBottom: '20px'}}>Typically Available Hours</div>
        <div style={styles.dayBox}>Mondays: {this.state.viewingUser && this.state.viewingUser.Availability[0].Monday}</div>
        <div style={styles.dayBox}>Tuesdays: {this.state.viewingUser && this.state.viewingUser.Availability[1].Tuesday}</div>
        <div style={styles.dayBox}>Wednesdays: {this.state.viewingUser && this.state.viewingUser.Availability[2].Wednesday}</div>
        <div style={styles.dayBox}>Thursdays: {this.state.viewingUser && this.state.viewingUser.Availability[3].Thursday}</div>
        <div style={styles.dayBox}>Fridays: {this.state.viewingUser && this.state.viewingUser.Availability[4].Friday}</div>
        <div style={styles.dayBox}>Saturdays: {this.state.viewingUser && this.state.viewingUser.Availability[5].Saturday}</div>
        <div style={styles.dayBox}>Sundays: {this.state.viewingUser && this.state.viewingUser.Availability[6].Sunday}</div>

        {this.state.viewingUser && this.state.viewingUser.AccountStatus === true ? <div style={styles.inviteBox}>
          <div style={{fontWeight: 'bold', marginBottom: '20px'}}>Choose Time of Video Date</div>
          <DateTimePicker onChange={(value)=> this.setInvDate(value)} value={this.state.invDateValue} 
              amPmAriaLabel="Select AM/PM"
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              hourAriaLabel="Hour"
              minuteAriaLabel="Minute"
              monthAriaLabel="Month"
              yearAriaLabel="Year"
              maxDetail="minute"
              disableClock={true}
            />
          <div style={{fontWeight: 'bold', margin: '20px 0px'}}>Time: {this.state.formattedDate}</div>
          <div style={{fontWeight: 'bold', margin: '20px 0px'}}>Place: <a href={this.state.meetingUrl} target={'blank'}>{this.state.meetingUrl}</a></div>
          {this.state.viewingUser && this.state.viewingUser.Invitations.some((invObj)=> invObj.requesterEmail === this.state.user.EmailID && invObj.dateTimestamp > Date.now()) ? <div style={styles.alreadyInvButton}>You currently already have an active invitation!</div> : <div style={styles.sendInvButton} onClick={()=> this.sendInvitation()}>Send Invitation</div>}
        </div> : <div style={{marginTop:  '40px', fontWeight: 'bold'}}>This user account is currently paused!</div>}

        <div style={styles.loaderImg} id='loaderImg'></div>
        {this.state.viewingUser && this.state.viewingUser.Invitations.some((invObj)=> invObj.requesterEmail === this.state.user.EmailID) ? <div style={{marginTop: '40px'}}>
          <div style={{fontWeight: 'bold', marginBottom: '20px'}}>Your Past Invitations:</div>
          {pastInvitations}
        </div> : undefined}
      </div>
    )
    






    return (
      <Fragment>
        <div className='masterContainer'>
          <div style={styles.navContainer} className='navContainer'>
            <div style={this.state.nav === 3 ? styles.navButton2 : styles.navButton1} className='navButton' onClick={()=> {this.setState({nav: 3}); this.getCurrentUser(); this.loadUsers()}}>
              <p>Explore</p>
            </div>
            <div style={this.state.nav === 2 ? styles.navButton2 : styles.navButton1} className='navButton' onClick={()=> {this.setState({nav: 2}); this.getCurrentUser()}}>
              <p>Dates</p>
            </div>
            <div style={this.state.nav === 1 ? styles.navButton2 : styles.navButton1} className='navButton' onClick={()=> this.setState({nav: 1})}>
              <p>Profile</p>
            </div>
            <div style={this.state.nav === 0 ? styles.navButton2 : styles.navButton1} className='navButton' onClick={()=> this.setState({nav: 0})}>
              <p>Settings</p>
            </div>
          </div>



          <div style={styles.settingsContainer} className='mainContainer'>
            <div style={styles.title}>Account Settings</div>
            <div style={styles.settingsTitleDiv}>Account Login Email:</div>
            <div style={{textAlign: 'left', marginBottom: '10px'}}>{this.state.accountEmail}</div>

            <div style={styles.settingsTitleDiv}>Video Date Invites:</div>
            <div style={{display: 'flex'}}>
              <div style={styles.creditsNumber}>{this.state.user.Credits}</div>
              <div style={styles.getCreditsButton} onClick={()=> {this.setState({showGetInvites: true}); logEvent(analytics, 'Get Invites Button Click')}}>Get More Invites</div>
            </div>

            <div style={styles.settingsTitleDiv}>Your Zoom / Google Meet URL:</div>
            <div style={styles.settingsDiv}>{this.state.meetingUrl ? <a href={this.state.meetingUrl} target={'blank'}>{this.state.meetingUrl}</a> : 'Not Set'}</div>
            <input style={styles.settingsInput} defaultValue={this.state.meetingUrl ? this.state.meetingUrl : undefined} placeholder={'Not Set'} value={this.state.meetingUrl} onChange={(e)=> this.setState({meetingUrl: e.target.value})}/>
            <div style={styles.meetingUrlExplanation}>{'You must provide a personal video meeting link to schedule a video date - once confirmed, it will be emailed out as the meeting destination to you and your date! If you don\'t have one yet, you can easily generate a new google meet link or a personal zoom link (zoom web -> profile -> meeting).'}</div>

            <div style={styles.settingsTitleDiv}>Email Notifications</div>
            <div style={styles.settingsDivA}>Date confirmations: <b>Always</b></div>
            <div  style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div style={styles.settingsDivA}>When you get an invite:</div>
              <div style={styles.settingsDivB}>{this.state.notifyInvite === true ? 'On' : 'Off'}</div>
              <div style={this.state.notifyInvite === true ? styles.currentStatus : styles.otherStatus} onClick={()=> this.setState({notifyInvite: true})}>On</div>
              <div style={this.state.notifyInvite === true ? styles.otherStatus : styles.currentStatus} onClick={()=> this.setState({notifyInvite: false})}>Off</div>
            </div>
            {/* <div  style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div style={styles.settingsDivA}>1 hour before your date:</div>
              <div style={styles.settingsDivB}>{this.state.notifyST === true ? 'On' : 'Off'}</div>
              <div style={this.state.notifyST === true ? styles.currentStatus : styles.otherStatus} onClick={()=> this.setState({notifyST: true})}>On</div>
              <div style={this.state.notifyST === true ? styles.otherStatus : styles.currentStatus} onClick={()=> this.setState({notifyST: false})}>Off</div>
            </div>
            <div  style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <div style={styles.settingsDivA}>12 hours before your date:</div>
              <div style={styles.settingsDivB}>{this.state.notifyLT === true ? 'On' : 'Off'}</div>
              <div style={this.state.notifyLT === true ? styles.currentStatus : styles.otherStatus} onClick={()=> this.setState({notifyLT: true})}>On</div>
              <div style={this.state.notifyLT === true ? styles.otherStatus : styles.currentStatus} onClick={()=> this.setState({notifyLT: false})}>Off</div>
            </div> */}
            <div style={styles.settingsTitleDiv}>Where To Receive Invites & Dates:</div>
            <div style={styles.settingsDiv}>{this.state.eventsEmail ? this.state.eventsEmail : 'Not Set'}</div>
            <input style={styles.settingsInput} defaultValue={this.state.eventsEmail ? this.state.eventsEmail : 'Not Set'} value={this.state.eventsEmail} onChange={(e)=> this.setState({eventsEmail: e.target.value})}/>

            <div style={styles.settingsTitleDiv}>City:</div>
            {this.state.editingSettings === true ?
              <select style={{width: '120px', border: 'solid 2px black', borderRadius: '4px', padding: '2px', marginBottom: '20px'}} value={this.state.city} onChange={(e)=> this.setState({city: e.target.value})}>
                <option value='New York, NY'>New York, NY</option>
              </select> : <div style={styles.settingsDiv}>{this.state.city}</div>}

            <div style={styles.settingsTitleDiv}>Account Status:</div>
            <div style={styles.settingsDiv}>{this.state.accountStatus === true ? 'Active - you can view members and schedule dates.' : 'Paused - you are hidden, cannot view members, cannot schedule dates.'}</div>
            <div style={this.state.accountStatus === true ? styles.currentStatus : styles.otherStatus} onClick={()=> this.setState({accountStatus: true})}>Active</div>
            <div style={this.state.accountStatus === true ? styles.otherStatus : styles.currentStatus} onClick={()=> this.setState({accountStatus: false})}>Paused</div>

            <div style={styles.settingsTitleDiv}>Support Email:</div>
            <div style={styles.settingsDivC}>hello@amiccio.com</div>

            <div style={styles.loaderImg} id='loaderImg'></div>
            <div style={styles.editSettingsButton} onClick={()=> this.setState({editingSettings: true})}>Edit</div>
            <div style={styles.saveSettingsButton} onClick={()=> this.saveSettings()}>Save</div>         
            <div style={styles.logout} onClick={()=> this.props.logout()}>Logout</div>
          </div>



          

          <div style={styles.profileContainer} className='mainContainer'>
            <div style={styles.title}>Profile</div>
            <div style={styles.settingsTitleDiv}>First Name:</div>
            <div style={styles.profileDiv}>{this.state.firstName ? this.state.firstName : 'Not Set'}</div>
            <input style={styles.profileInput} defaultValue={this.state.firstName ? this.state.firstName : undefined} placeholder={'Not Set'} value={this.state.firstName} onChange={(e)=> this.setState({firstName: e.target.value})}/>

            <div style={styles.settingsTitleDiv}>Bio:</div>
            <div style={styles.profileDiv}>{this.state.bio ? this.state.bio : 'Not Set'}</div>
            <input style={styles.profileInput} defaultValue={this.state.bio ? this.state.bio : undefined} placeholder={'Not Set'} value={this.state.bio} onChange={(e)=> this.setState({bio: e.target.value})}/>

            <div style={styles.settingsTitleDiv}>Profession:</div>
            <div style={styles.profileDiv}>{this.state.profession ? this.state.profession : 'Not Set'}</div>
            <input style={styles.profileInput} defaultValue={this.state.profession ? this.state.profession : undefined} placeholder={'Not Set'} value={this.state.profession} onChange={(e)=> this.setState({profession: e.target.value})}/>

            <div style={styles.settingsTitleDiv}>Gender:</div>
            <div style={styles.profileDiv}>{this.state.gender ? this.state.gender : 'Not Set'}</div>
            {this.state.user.Gender === undefined || this.state.user.Gender === 'Man' ? <div style={this.state.gender === 'Man' ? styles.activeGender : styles.inactiveGender} onClick={()=> this.state.user.GenderFinalized === false ? this.setState({gender: 'Man'}) : undefined}>Man</div> : undefined}
            {this.state.user.Gender === undefined || this.state.user.Gender === 'Woman' ? <div style={this.state.gender === 'Woman' ? styles.activeGender : styles.inactiveGender} onClick={()=> this.state.user.GenderFinalized === false ? this.setState({gender: 'Woman'}) : undefined}>Woman</div> : undefined}
            {this.state.editingProfile === true && this.state.user.GenderFinalized === false ? <div style={styles.genderWarning}>Attention! Once gender is saved, you will not be able to change your selection!</div> : undefined}

            <div style={styles.settingsTitleDiv}>Age:</div>
            <div style={styles.profileDiv}>{this.state.age ? this.state.age : 'Not Set'}</div>
            <input style={styles.profileInput} defaultValue={this.state.age ? this.state.age : undefined} placeholder={'Not Set'} type='number' value={this.state.age} onChange={(e)=> {
              if (e.target.value > 100 || e.target.value < 0) {
                alert('You must choose an age between 18 and 100!')
              } else {
                this.setState({age: Math.round(e.target.value)})
              }
            }}/>
            
            <div style={styles.settingsTitleDiv}>Photos:</div>
            <div className='photosContainer'>
              <div style={styles.photoContainer}>
                {this.state.photo1 !== undefined ? <img width="120px" height="150px" src={this.state.photo1} style={styles.photoBox}/> : <div style={styles.photoBox}></div>}
                <input id='files1' type="file" accept="image/jpg, image/jpeg, image/png" style={{display: 'none'}} onChange={(e)=> this.onImageChange1(e)}/>
                {this.state.photo1 === undefined ? <label htmlFor='files1' style={styles.photoInputButton}>Choose Photo</label> : <div style={styles.photoRemoveButton} onClick={()=> this.removePhoto(1)}>Remove Photo</div>}
              </div>
              <div style={styles.photoContainer}>
                {this.state.photo2 !== undefined ? <img width="120px" height="150px" src={this.state.photo2} style={styles.photoBox}/> : <div style={styles.photoBox}></div>}
                <input id='files2' type="file" accept="image/jpg, image/jpeg, image/png" style={{display: 'none'}} onChange={(e)=> this.onImageChange2(e)}/>
                {this.state.photo2 === undefined ? <label htmlFor='files2' style={styles.photoInputButton}>Choose Photo</label> : <div style={styles.photoRemoveButton} onClick={()=> this.removePhoto(2)}>Remove Photo</div>}
              </div>
              <div style={styles.photoContainer}>
                {this.state.photo3 !== undefined ? <img width="120px" height="150px" src={this.state.photo3} style={styles.photoBox}/> : <div style={styles.photoBox}></div>}
                <input id='files3' type="file" accept="image/jpg, image/jpeg, image/png" style={{display: 'none'}} onChange={(e)=> this.onImageChange3(e)}/>
                {this.state.photo3 === undefined ? <label htmlFor='files3' style={styles.photoInputButton}>Choose Photo</label> : <div style={styles.photoRemoveButton} onClick={()=> this.removePhoto(3)}>Remove Photo</div>}
              </div>
              <div style={styles.photoContainer}>
                {this.state.photo4 !== undefined ? <img width="120px" height="150px" src={this.state.photo4} style={styles.photoBox}/> : <div style={styles.photoBox}></div>}
                <input id='files4' type="file" accept="image/jpg, image/jpeg, image/png" style={{display: 'none'}} onChange={(e)=> this.onImageChange4(e)}/>
                {this.state.photo4 === undefined ? <label htmlFor='files4' style={styles.photoInputButton}>Choose Photo</label> : <div style={styles.photoRemoveButton} onClick={()=> this.removePhoto(4)}>Remove Photo</div>}
              </div>
            </div>          
            
            <div style={styles.loaderImg} id='loaderImg'></div>
            <div style={styles.editProfileButton} onClick={()=> this.setState({editingProfile: true})}>Edit</div>
            <div style={styles.saveProfileButton} onClick={()=> this.saveProfile()}>Save</div>
          </div>




          <div style={styles.datesContainer} className='datesContainer'>
            <div style={styles.datesPagePartition} className='datesPagePartition'>

              <div style={styles.datesLeftPartition} className='datesLeftPartition'>
                <div style={styles.setAvailabilityTitle}>Set Your Availability</div>
                <div style={styles.setAvailabilityBox} className='setAvailabilityBox'>
                  <div style={styles.dayContainer}>
                    <div style={styles.dayLabel}>Mondays</div>
                    <div style={styles.hoursInput} className='hoursInput'>{this.state.availMonday ? this.state.availMonday : <span style={{color: '#a2a2a2'}}>Not Set</span>}</div>
                    <input style={styles.availInput} className='availInput' defaultValue={this.state.availMonday ? this.state.availMonday : undefined} placeholder={'Example: "6pm-9pm"'} value={this.state.availMonday} onChange={(e)=> this.setState({availMonday: e.target.value})}/>
                  </div>
                  <div style={styles.dayContainer}>
                    <div style={styles.dayLabel}>Tuesdays</div>
                    <div style={styles.hoursInput} className='hoursInput'>{this.state.availTuesday ? this.state.availTuesday : <span style={{color: '#a2a2a2'}}>Not Set</span>}</div>
                    <input style={styles.availInput} className='availInput' defaultValue={this.state.availTuesday ? this.state.availTuesday : undefined} placeholder={'Example: "6pm-9pm"'} value={this.state.availTuesday} onChange={(e)=> this.setState({availTuesday: e.target.value})}/>
                  </div>
                  <div style={styles.dayContainer}>
                    <div style={styles.dayLabel}>Wednesdays</div>
                    <div style={styles.hoursInput} className='hoursInput'>{this.state.availWednesday ? this.state.availWednesday : <span style={{color: '#a2a2a2'}}>Not Set</span>}</div>
                    <input style={styles.availInput} className='availInput' defaultValue={this.state.availWednesday ? this.state.availWednesday : undefined} placeholder={'Example: "6pm-9pm"'} value={this.state.availWednesday} onChange={(e)=> this.setState({availWednesday: e.target.value})}/>
                  </div>
                  <div style={styles.dayContainer}>
                    <div style={styles.dayLabel}>Thursdays</div>
                    <div style={styles.hoursInput} className='hoursInput'>{this.state.availThursday ? this.state.availThursday : <span style={{color: '#a2a2a2'}}>Not Set</span>}</div>
                    <input style={styles.availInput} className='availInput' defaultValue={this.state.availThursday ? this.state.availThursday : undefined} placeholder={'Example: "6pm-9pm"'} value={this.state.availThursday} onChange={(e)=> this.setState({availThursday: e.target.value})}/>
                  </div>
                  <div style={styles.dayContainer}>
                    <div style={styles.dayLabel}>Fridays</div>
                    <div style={styles.hoursInput} className='hoursInput'>{this.state.availFriday ? this.state.availFriday : <span style={{color: '#a2a2a2'}}>Not Set</span>}</div>
                    <input style={styles.availInput} className='availInput' defaultValue={this.state.availMonday ? this.state.availFriday : undefined} placeholder={'Example: "6pm-9pm"'} value={this.state.availFriday} onChange={(e)=> this.setState({availFriday: e.target.value})}/>
                  </div>
                  <div style={styles.dayContainer}>
                    <div style={styles.dayLabel}>Saturdays</div>
                    <div style={styles.hoursInput} className='hoursInput'>{this.state.availSaturday ? this.state.availSaturday : <span style={{color: '#a2a2a2'}}>Not Set</span>}</div>
                    <input style={styles.availInput} className='availInput' defaultValue={this.state.availMonday ? this.state.availSaturday : undefined} placeholder={'Example: "6pm-9pm"'} value={this.state.availSaturday} onChange={(e)=> this.setState({availSaturday: e.target.value})}/>
                  </div>
                  <div style={styles.dayContainer}>
                    <div style={styles.dayLabel}>Sundays</div>
                    <div style={styles.hoursInput} className='hoursInput'>{this.state.availSunday ? this.state.availSunday : <span style={{color: '#a2a2a2'}}>Not Set</span>}</div>
                    <input style={styles.availInput} className='availInput' defaultValue={this.state.availSunday ? this.state.availSunday : undefined} placeholder={'Example: "6pm-9pm"'} value={this.state.availSunday} onChange={(e)=> this.setState({availSunday: e.target.value})}/>
                  </div>

                  <div style={styles.loaderImg} id='loaderImg'></div>
                  <div style={styles.editHoursButton} onClick={()=> this.setState({editingAvailability: true})}>Edit</div>
                  <div style={styles.saveHoursButton} onClick={()=> this.saveAvailability()}>Save</div>
                </div>
              </div>

              <div style={styles.datesRightPartition} className='datesRightPartition'>
                <div style={{marginBottom: '40px', justifyContent: 'center', display: 'flex'}}>
                  <div style={this.state.activeUpcomingStatus === 1 ? styles.activeBar : styles.inactiveBar} onClick={()=> this.setState({activeUpcomingStatus: 1})}>Upcoming Dates</div>
                  <div style={this.state.activeUpcomingStatus === 0 ? styles.activeBar : styles.inactiveBar} onClick={()=> this.setState({activeUpcomingStatus: 0})}>Past Dates</div>
                </div>
                <div style={styles.datesArrayBox}>{this.state.activeUpcomingStatus === 1 ? upcomingDatesArray : pastDatesArray}</div>

                <div style={styles.invitationsTitle}>Date Invitations</div>
                <div style={styles.invitationsArrayBox}>{invitationsArray}</div>
              </div>
            </div>
          </div>




          <div style={styles.exploreContainer}>
            {this.state.loading === true ? <div style={styles.loader2Img} id='loaderImg'></div> : exploreContent}
          </div>





          <div style={styles.viewMemberContainer} className='viewMemberContainer'>
            {this.state.viewingUser ? (
                <div style={styles.pagePartition} className='pagePartition'>
                  <div style={styles.leftPartition}>
                    <img style={styles.memberPhotoA} src={this.state.viewingUser.Photos[this.state.counterA]} onClick={()=> this.rotatePhotos()} className='memberPhotoA'/>
                  </div>
                  <div style={styles.rightPartition} className='rightPartition'>
                    <div style={styles.memberFirstName}>{this.state.viewingUser.FirstName}, {this.state.viewingUser.Age}</div>
                    <div style={styles.memberAttributesBox} className='memberAttributesBox'>
                      <div style={styles.memberLocation}><img src={locationIcon} width={12} height={12}/> {this.state.viewingUser.Cities[0]}</div>
                      <div style={styles.memberCreatedDate}><img src={peopleIcon} width={12} height={12}/> Joined {months[memberCreatedDate.getMonth()]} {memberCreatedDate.getFullYear()}</div>
                      {professionBox}
                    </div>
                    <div style={styles.memberBio}>{this.state.viewingUser.Bio}</div>
                    <div style={styles.secondaryPhotosContainer}>
                      <img style={styles.memberPhotoB} className='memberPhotoB' src={this.state.viewingUser.Photos[this.state.counterB]} onClick={()=> this.rotatePhotos()}/>
                      {this.state.viewingUser && this.state.viewingUser.Photos.length > 2 ? <img style={styles.memberPhotoB} className='memberPhotoB' src={this.state.viewingUser.Photos[this.state.counterC]} onClick={()=> this.rotatePhotos()}/> : undefined}
                      {this.state.viewingUser && this.state.viewingUser.Photos.length > 3 ? <img style={styles.memberPhotoB} className='memberPhotoB' src={this.state.viewingUser.Photos[this.state.counterD]} onClick={()=> this.rotatePhotos()}/> : undefined}
                    </div>
                    {this.state.user.MeetingUrl !== undefined ? requestDateBox : setUrlWarningBox}
                  </div>
                  
                </div>
              ) : (<div>Oops! Reload and try again.</div>)
            }
          </div>


          <div style={styles.faqButton} className='faqButton' onClick={()=> this.setState({showFAQ: true})}>Guide & FAQ</div>
          <div style={styles.faqBox}>
            <div style={{marginBottom: '10px', fontWeight: 'bold'}}>Guide & FAQ</div>
            <div style={{fontSize: '14px', margin: '10px'}}>Amiccio Video Dating allows you to invite members to video dates, so you can skip endless swiping, chats that go nowhere, and catfishing!</div>
            <ol style={{textAlign: 'left', marginLeft: '-20px'}}>    
              <li style={{fontSize: '14px', margin: '10px'}}>List your availability, and fill out your profile</li>
              <li style={{fontSize: '14px', margin: '10px'}}>Start with 10 free video date invitations</li>
              <li style={{fontSize: '14px', margin: '10px'}}>If invites are accepted, you get email confirmations of your video date details</li>
              <li style={{fontSize: '14px', margin: '10px'}}>Be respectful and show up to scheduled dates!</li>
              <li style={{fontSize: '14px', margin: '10px'}}>Get 20 more invites for each friend you refer!</li>
            </ol>
            
            <div style={styles.closeFAQButton} onClick={()=> this.setState({showFAQ: false})}>Close</div>
          </div>

          <div style={styles.overlay}>
            <div style={styles.getInvitesModal}>
              <div style={styles.modalButtonA} onClick={()=> this.shareReferralLink()}>Refer a friend - get 20 invites!</div>
              <div style={{marginTop: '-10px', marginBottom: '20px'}}>Share this link:</div>
              <div style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={()=> this.shareReferralLink()}>https://app.amiccio.com/?referrer={this.state.user.EmailID}</div>
              {/* <div style={styles.modalButton}>Get 40 invites for $20 (soon)</div>
              <div style={styles.modalButton}>Get 100 invites for $40 (soon)</div> */}
              <div style={styles.closeModalButton} onClick={()=> this.setState({showGetInvites: false})}>Close</div>
            </div>
          </div>
          


        </div>
        
        

      </Fragment>
    )
  }
  
}
